import React from 'react';
import { Global, css } from '@emotion/react';

const globalStyles = (
  <Global
    styles={css`
      body {
        font-family: 'Inter', sans-serif;
      }
    `}
  />
);

export default globalStyles;
