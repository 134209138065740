import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, navigate } from 'gatsby';
import clsx from 'clsx';

import { AuthContext, AuthModalContext } from 'auth';
import styled from '@emotion/styled';
import useLayout from 'hooks/useLayout';
import useSitedataAssets from 'hooks/useSitedataAssets';
import { Icon, AuthMenu, Button } from 'components';
import listingStatuses from 'views/SearchPage/listingStatuses.json';
import { info } from 'sitedata';
import SearchAutocomplete, { AutocompleteSubmitValue } from '../SearchAutocomplete';
import AsideMenu from './AsideMenu';
import links from './links';

interface HeaderProps {
  className?: string;
  hasSearch?: boolean;
  hasBorder?: boolean;
  isLight?: boolean;
}

const Header: React.FC<HeaderProps> = ({
  hasSearch = false,
  isLight = false,
  hasBorder = false,
  className
}) => {
  const { t } = useTranslation();
  const { logo, logoWhite } = useSitedataAssets();
  const { authorized } = useContext(AuthContext);
  const { setAuthModalOpen } = useContext(AuthModalContext);
  const [openMenuModal, setOpenMenuModal] = useState(false);
  const layout = useLayout();
  const logoSrc = (isLight ? logoWhite : logo) || undefined;

  const handleAutocompleteSubmit = ({ suggestion, query }: AutocompleteSubmitValue) => {
    // eslint-disable-next-line no-param-reassign
    if (suggestion) delete (suggestion as any).name;
    const params: any = suggestion || {};
    if (query) params.search_string = query;
    const searchParams = new URLSearchParams(params);
    [listingStatuses.Active, listingStatuses.UnderContractOrPending].forEach(item =>
      searchParams.append('listing_status', item)
    );
    const url = `/search?${searchParams.toString()}`;
    navigate(url);
  };

  const getLayout = () => {
    switch (layout) {
      case 'mobile':
      case 'tablet':
        return (
          <StyledHeader
            className={clsx(
              'header',
              { light: isLight, 'has-search': hasSearch, 'has-border': hasBorder },
              className
            )}>
            <div className="header__logo-block">
              <Link to="/" className="header__logo">
                <img
                  src={logoSrc}
                  alt="logo"
                  className="header__logo-img"
                  width="43px"
                  height="13px"
                />
              </Link>
              {hasSearch && (
                <SearchAutocomplete onSubmit={handleAutocompleteSubmit} placeholder="Search" />
              )}
            </div>
            <Button simple onClick={() => setOpenMenuModal(true)} className="header__menu-trigger">
              <Icon className="header__menu-trigger-icon" name="burger-menu" />
            </Button>
            <AsideMenu open={openMenuModal} onClose={() => setOpenMenuModal(false)} />
          </StyledHeader>
        );
      default:
        return (
          <StyledHeader
            className={clsx(
              'header',
              { light: isLight, 'has-search': hasSearch, 'has-border': hasBorder },
              className
            )}>
            <div className="header__logo-block">
              <Link to="/" className="header__logo" data-cy="header_logo">
                <img
                  src={logoSrc}
                  alt="logo"
                  className="header__logo-img"
                  width="43px"
                  height="13px"
                />
              </Link>
              {hasSearch && (
                <SearchAutocomplete onSubmit={handleAutocompleteSubmit} placeholder="Search" />
              )}
            </div>
            <nav className="header-menu">
              <Link className="header-menu__item" to={links.buy} data-cy="button_buy">
                {t('header.buy')}
              </Link>
              <Link className="header-menu__item" to={links.sell} data-cy="button_sell">
                {t('header.sell')}
              </Link>
              <Link className="header-menu__item" to={links.aboutUs} data-cy="button_about_us">
                {t('header.about_us')}
              </Link>
              {info.showOurTeamMenu && (
                <Link className="header-menu__item" to={links.ourTeam} data-cy="button_out_team">
                  Our team
                </Link>
              )}
              <div className="header-controls">
                <Link
                  className="header-controls__item line"
                  to={links.savedListings}
                  onClick={e => {
                    if (!authorized) {
                      e.preventDefault();
                      setAuthModalOpen(true);
                    }
                  }}
                  data-cy="button_saved_listings">
                  {t('header.saved_listings')}
                </Link>
                <Link
                  className="header-controls__item line"
                  to={links.savedSearches}
                  onClick={e => {
                    if (!authorized) {
                      e.preventDefault();
                      setAuthModalOpen(true);
                    }
                  }}
                  data-cy="button_saved_searches">
                  {t('header.saved_searches')}
                </Link>
                {authorized ? (
                  <AuthMenu className="header-controls__item" />
                ) : (
                  <Button
                    className="header-controls__item"
                    simple
                    onClick={() => setAuthModalOpen(true)}
                    data-cy="button_register">
                    {t('common.login')}/{t('common.register')}
                  </Button>
                )}
              </div>
            </nav>
          </StyledHeader>
        );
    }
  };

  return getLayout();
};

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 50;
  position: relative;
  padding: 16px;
  box-sizing: border-box;
  &.light {
    .header {
      &__menu-trigger {
        border-radius: 0;
      }
      &__menu-trigger-icon {
        fill: ${props => props.theme.colors.white};
      }
    }
    .header-menu {
      &__item {
        color: ${props => props.theme.colors.white};
      }
    }
    .header-controls {
      &__item,
      &__item.button {
        color: ${props => props.theme.colors.white};
        fill: ${props => props.theme.colors.white};
        font-weight: normal;

        &.auth {
          margin: 0;

          .dropdown__button {
            color: #fff;
            fill: #fff;
            padding: 0;
          }
        }

        &.line:after {
          background: ${props => props.theme.colors.white};
        }
      }
    }
  }
  &.has-search {
    padding: 8px 16px;
    .header-controls {
      .dropdown {
        .dropdown__text {
          max-width: 250px;
        }
      }
    }
  }
  &.has-border {
    border-bottom: 1px solid ${props => props.theme.colors.creamyGray};
  }
  .search {
    margin: 0 0 0 32px;
    &__input-and-dropdown {
      background: ${props => props.theme.colors.white};
    }
  }
  .header {
    &__logo-block {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__logo {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 24px;
    }
    &__logo-img {
      object-fit: cover;
      width: 100%;
    }
    &__menu-trigger-icon {
      fill: ${props => props.theme.colors.black};
    }
    &__menu-trigger {
      &.button {
        width: 32px;
        height: 32px;
      }
    }
  }
  .header-menu {
    display: flex;
    align-items: center;
    &__item {
      font-size: 16px;
      line-height: 24px;
      color: ${props => props.theme.colors.black};
      text-decoration: none;
      margin: 0 0 0 24px;
      flex-shrink: 0;
    }
  }
  .header-controls {
    &__item,
    &__item.button {
      font-size: 16px;
      line-height: 24px;
      color: ${props => props.theme.colors.black};
      fill: ${props => props.theme.colors.black};
      text-decoration: none;
      position: relative;
      margin: 0 0 0 24px;
      flex-shrink: 0;

      &.line:after {
        content: '';
        position: absolute;
        width: 1px;
        background: ${props => props.theme.colors.black};
        height: 16px;
        top: 50%;
        transform: translateY(-50%);
        right: -12px;
      }
    }
  }
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    .search {
      min-width: 342px;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    padding: 16px 8px 16px 16px;
    &.has-search {
      padding: 8px 8px 8px 16px;
      .header {
        &__logo-block {
          flex: 1;
        }
      }
    }
    .search {
      margin: 0 auto;
      min-width: 465px;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    .header-controls {
      margin: 0;
    }
    .search {
      max-width: 220px;
      min-width: initial;
      margin: 0 auto;
    }
  }
`;

export default Header;
