/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
import * as defaultComponents from './common/components';

export const { info, siteName, fonts, isDevEnv } = require(`./sitedata`);

export { default as defaultTheme } from './common/theme.json';

export const theme = require(`./${siteName}/theme.json`);

export const locales = {
  en: require(`./${siteName}/locales/en/translation.json`)
};

export const Components: typeof defaultComponents = require(`./${siteName}/components`);

let _globalStyles = require(`./common/globalStyles`);
try {
  _globalStyles = require(`./${siteName}/globalStyles`).default;
} catch (e) {
  if (isDevEnv) console.info(`Sitedata | globalStyles : defaults are used`);
}
export const globalStyles = _globalStyles.default;
