import { graphql, useStaticQuery } from 'gatsby';
import { useMemo } from 'react';

const getItemUrl = (item: any): string | null => {
  return item?.publicURL || null;
};

type SitedataAssets = {
  [key: string]: string | null;
};

export default function useSitedataAssets(): SitedataAssets {
  const items = useStaticQuery(graphql`
    {
      coverImage: file(name: { eq: "cover" }, sourceInstanceName: { eq: "sitedataAssets" }) {
        publicURL
      }
      logo: file(name: { eq: "logo" }, sourceInstanceName: { eq: "sitedataAssets" }) {
        publicURL
      }
      logoWhite: file(name: { eq: "logo-white" }, sourceInstanceName: { eq: "sitedataAssets" }) {
        publicURL
      }
      logoSmall: file(name: { eq: "logo-small" }, sourceInstanceName: { eq: "sitedataAssets" }) {
        publicURL
      }
      logoSmallWhite: file(
        name: { eq: "logo-small-white" }
        sourceInstanceName: { eq: "sitedataAssets" }
      ) {
        publicURL
      }
    }
  `);

  const assets = useMemo(() => {
    const result = {};
    Object.entries(items).forEach(([key, value]) => {
      result[key] = getItemUrl(value);
    });
    return result;
  }, [items]);

  return assets;
}
