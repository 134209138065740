import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import logo from 'assets/images/footer-logo.png';
import { ContactForm, Modal } from 'components';
import { Trans, useTranslation } from 'react-i18next';

const markets = [
  { id: 1, title: 'Alabama Real Estate', link: '/' },
  { id: 2, title: 'Alaska Real Estate', link: '/' },
  { id: 3, title: 'Arizona Real Estate', link: '/' },
  { id: 4, title: 'Arkansas Real Estate', link: '/' }
];
const searches = [
  { id: 1, title: 'Apartments for Rent Near Me', link: '/' },
  { id: 2, title: 'Houses for Rent Near Me', link: '/' },
  { id: 3, title: 'Houses for Sale Near Me', link: '/' },
  { id: 4, title: 'Houses for Sale Near Me by Owner', link: '/' }
];
const brokers = [
  { id: 1, title: 'Facebook', link: '/' },
  { id: 2, title: 'Twitter', link: '/' },
  { id: 3, title: 'Instagram', link: '/' },
  { id: 4, title: 'Pinterest', link: '/' }
];
const info = [
  { id: 1, title: 'U.S. Property Records', link: '/' },
  { id: 2, title: 'Popular Counties', link: '/' },
  { id: 3, title: 'Rental Communities', link: '/' },
  { id: 4, title: 'Real Estate Leads', link: '/' }
];

const Footer = props => {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState<string>();

  const footerLinks = [
    { id: 1, title: t('footer.home'), link: '/' },
    { id: 2, title: t('footer.buy'), link: '/' },
    { id: 3, title: t('footer.sell'), link: '/' },
    { id: 4, title: t('footer.join_our_team'), link: '/' },
    { id: 5, title: t('common.contact_us'), link: '#contact-us', modal: 'contact-us' },
    { id: 6, title: t('footer.our_agents'), link: '/' }
  ];

  const handleLinkClick = (e, item) => {
    if (item.modal) {
      e.preventDefault();
      setOpenModal(item.modal);
    }
  };

  return (
    <StyledFooter className="footer" {...props}>
      <div className="footer-wrapper">
        <div className="footer-info-wrapper">
          <div className="footer-info">
            <h4 className="footer-info__title">Real Estate Markets</h4>
            <div className="footer-info__links">
              {markets.map(item => {
                return (
                  <Link key={item.id} to={item.link} className="footer-info__link">
                    {item.title}
                  </Link>
                );
              })}
              <Link to="/" className="footer-info__link footer-info__link_more">
                More
              </Link>
            </div>
          </div>
          <div className="footer-info-container">
            <div className="footer-info">
              <h4 className="footer-info__title">Popular Searches</h4>
              <div className="footer-info__links">
                {searches.map(item => {
                  return (
                    <Link key={item.id} to={item.link} className="footer-info__link">
                      {item.title}
                    </Link>
                  );
                })}
                <Link to="/" className="footer-info__link footer-info__link_more">
                  More
                </Link>
              </div>
            </div>
            <div className="footer-info">
              <h4 className="footer-info__title">Explore Broker</h4>
              <div className="footer-info__links">
                {brokers.map(item => {
                  return (
                    <Link key={item.id} to={item.link} className="footer-info__link">
                      {item.title}
                    </Link>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="footer-info">
            <h4 className="footer-info__title">For Professionals</h4>
            <div className="footer-info__links">
              {info.map(item => {
                return (
                  <Link key={item.id} to={item.link} className="footer-info__link">
                    {item.title}
                  </Link>
                );
              })}
            </div>
          </div>
        </div>
        <div className="footer__bottom-info">
          <div className="footer__bottom-infо-wrapper">
            <p className="footer__firm-name">{t('footer.company_name')}</p>
            <p className="footer__firm-adress">{t('footer.address')}</p>
            <nav className="footer-links">
              {footerLinks.map(item => {
                return (
                  <Link
                    key={item.id}
                    to={item.link}
                    className="footer-link"
                    onClick={e => handleLinkClick(e, item)}>
                    {item.title}
                  </Link>
                );
              })}
            </nav>
            <p className="footer__description">
              <Trans i18nKey="footer.powered_by">
                Powered by
                <a href={t('links.powered_by')}>{t('footer.powered_by_site')}</a>
              </Trans>
              <br />
              <Trans i18nKey="footer.copyright">
                Copyright © {{ year: new Date().getFullYear() }}{' '}
                <a href={t('links.company')}>{t('footer.copyright_site')}</a>
              </Trans>
            </p>
          </div>
          <div className="footer__logo" data-cy="footer_logo">
            <img src={logo} alt="footer-logo" className="footer__logo-img" />
          </div>
        </div>
      </div>
      <Modal
        className="contact-us-modal"
        open={openModal === 'contact-us'}
        onClose={() => setOpenModal(undefined)}>
        <ContactForm onSubmit={() => setOpenModal(undefined)} />
      </Modal>
    </StyledFooter>
  );
};

const StyledFooter = styled.div`
  padding: 85px 16px 139px;
  display: flex;
  justify-content: center;
  .footer {
    &__bottom-info {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &__bottom-infо-wrapper {
      padding: 0 21px 0 0;
    }
    &__firm-name,
    &__firm-adress {
      font-size: 14px;
      line-height: 22px;
      color: ${props => props.theme.colors.darkGray};
      margin: 0 0 10px;
    }
    &__description {
      font-size: 12px;
      line-height: 22px;
      color: ${props => props.theme.colors.darkGray};
      margin: 0;
      a {
        color: ${props => props.theme.colors.primary};
      }
    }
    &__logo {
      width: 100px;
      flex-shrink: 0;
    }
    &__logo-img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .footer-links {
    margin: 0 0 10px;
  }
  .footer-link {
    font-size: 14px;
    line-height: 22px;
    color: ${props => props.theme.colors.darkGray};
    position: relative;
    margin: 0 18px 0 0;
    text-decoration: none;
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      transform: translate(50%, -50%);
      right: -9px;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background: ${props => props.theme.colors.darkGray};
    }
    &:last-of-type {
      margin: 0;
      &:after {
        display: none;
      }
    }
  }
  .footer-wrapper {
    max-width: 1025px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .footer-info-wrapper {
    display: flex;
    justify-content: space-between;
    flex: 1;
    margin: 0 0 58px;
  }
  .footer-info-container {
    display: flex;
    .footer-info {
      &:first-of-type {
        padding: 0 30px 0 0;
      }
      &:last-of-type {
        padding: 0 0 0 30px;
      }
    }
  }
  .footer-info {
    &__title {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      margin: 0 0 18px;
      color: ${props => props.theme.colors.black};
      cursor: default;
    }
    &__links {
      display: flex;
      flex-direction: column;
    }
    &__link {
      font-size: 14px;
      line-height: 22px;
      color: ${props => props.theme.colors.black};
      text-decoration: none;
      margin: 0 0 4px;
    }
    &__link_more {
      font-weight: 500;
      color: ${props => props.theme.colors.primary};
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    padding: 30px 16px 55px;
    justify-content: flex-start;
    .footer {
      &__bottom-info {
        align-items: flex-start;
        flex-direction: column;
      }
      &__description {
        margin: 0 0 20px;
      }
      &__firm-name {
        font-size: 12px;
        line-height: 22px;
        margin: 0 0 4px;
      }
      &__firm-adress {
        font-size: 12px;
        line-height: 22px;
        margin: 0 0 4px;
      }
      &__logo {
        width: 63px;
      }
    }
    .footer-wrapper {
      max-width: 700px;
    }
    .footer-info-wrapper {
      flex-direction: column;
      margin: 0;
    }
    .footer-info-container {
      flex-direction: column;
      .footer-info {
        &:first-of-type,
        &:last-of-type {
          padding: 0;
        }
      }
    }
    .footer-info {
      &__title {
        font-size: 12px;
        line-height: 24px;
        color: ${props => props.theme.colors.darkGray};
        margin: 0 0 4px;
      }
      &__links {
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
        margin: 0 0 20px;
      }
      &__link {
        font-size: 12px;
        line-height: 20px;
        color: ${props => props.theme.colors.darkGray};
        position: relative;
        margin: 0 16px 0 0;
        text-decoration: none;
        &:after {
          content: '';
          position: absolute;
          top: 50%;
          transform: translate(50%, -50%);
          right: -8px;
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background: ${props => props.theme.colors.darkGray};
        }
        &:last-of-type {
          margin: 0;
          &:after {
            display: none;
          }
        }
      }
      &__link_more {
        font-weight: normal;
        color: ${props => props.theme.colors.darkGray};
        text-transform: uppercase;
      }
    }
    .footer-link {
      margin: 0 16px 0 0;
      font-size: 12px;
      line-height: 20px;
      display: inline-flex;
      &:after {
        right: -8px;
      }
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    padding: 40px 16px 80px;
    .footer {
      &__description {
        margin: 0 0 39px;
      }
      &__bottom-info-wrapper {
        padding: 0 38px 0 0;
      }
    }
    .footer-info {
      &__links {
        margin: 0 0 16px;
      }
    }
    .footer-info-wrapper {
      margin: 0 0 33px;
    }
  }
`;

export default Footer;
