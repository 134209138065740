import React, { useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';

import styled from '@emotion/styled';
import clsx from 'clsx';
import Button from './Button';
import Icon from './Icon';

export interface ModalProps extends React.HTMLAttributes<HTMLDivElement> {
  open?: boolean;
  modalTitle?: React.ReactNode;
  modalHead?: boolean;
  children?: React.ReactNode;
  renderContainer?: React.ComponentType<any>;
  hideClose?: boolean;
  onClose?: () => void;
}

const Modal: React.FC<ModalProps> = ({
  open,
  modalTitle,
  modalHead = true,
  renderContainer: CustomContainer,
  hideClose,
  onClose,
  children,
  className,
  ...rest
}) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const modalRoot = useRef<any>();

  useEffect(() => {
    modalRoot.current = document.getElementById('modal-root');
  }, []);

  const ModalContainer = CustomContainer || StyledModalContainer;

  const modal = (
    <CSSTransition in={open} timeout={{ appear: 200 }} classNames="fade" unmountOnExit>
      <ModalContainer className={clsx('modal-container styleguide-v2', className)}>
        <div onClick={onClose} className="modal-bg">
          <StyledModal
            onClick={e => e.stopPropagation()}
            ref={modalRef}
            className={clsx('modal-content', { open })}
            {...rest}>
            {modalHead && (
              <div className="modal-head">
                {!hideClose && (
                  <Button onClick={onClose} className="button-close" simple>
                    <Icon name="mini-x" size={12} />
                  </Button>
                )}
                <h2 className="title">{modalTitle}</h2>
              </div>
            )}
            <div className="modal-body">{children}</div>
          </StyledModal>
        </div>
      </ModalContainer>
    </CSSTransition>
  );
  // return modal;
  // if (!modalRoot.current) return modal;
  if (!modalRoot.current) return null;
  return ReactDOM.createPortal(modal, modalRoot.current);
};

export default React.memo(Modal);

const StyledModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;

  .modal-bg {
    display: flex;
    justify-content: center;
    background: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 100%;
    padding: 20px;
    box-sizing: border-box;
    overflow: auto;
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    .modal-bg {
      padding: 12px;
    }
  }
`;

const StyledModal = styled.div`
  min-width: 300px;
  background: #ffffff;
  z-index: 1001;
  padding: 24px;
  border-radius: ${props => props.theme.misc.borderRadius};
  margin: auto;
  box-sizing: border-box;
  position: relative;

  &.fitted {
    top: initial;
    margin: 0;
  }

  .modal-head {
    min-height: 12px;

    .title {
      display: inline-block;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      margin: 0;
    }

    .button-close {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      width: 12px;
      height: 12px;
      position: absolute;
      top: 28px;
      right: 28px;
      fill: ${props => props.theme.colors.primary};
      border: 0;
      margin: 0;

      svg {
        flex-shrink: 0;
        margin: 0;
      }
    }
  }
`;
