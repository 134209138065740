import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'gatsby';
import clsx from 'clsx';

import { AuthContext, AuthModalContext } from 'auth';
import styled from '@emotion/styled';
import { AuthMenu } from 'components';
import { info } from 'sitedata';
import Modal from '../Modal';
import links from './links';

interface AsideMenuProps {
  className?: string;
  open: boolean;

  onClose: () => void;
}

const AsideMenu: React.FC<AsideMenuProps> = ({ className, open, onClose, ...props }) => {
  const { t } = useTranslation();
  const { authorized } = useContext(AuthContext);
  const { setAuthModalOpen } = useContext(AuthModalContext);

  return (
    <StyledModal className="main-menu-modal" open={open} onClose={onClose}>
      <StyledAsideMenu className={clsx('popup-menu', className)} {...props}>
        {authorized ? (
          <AuthMenu className="popup-menu__item auth" noPopper onClose={onClose} />
        ) : (
          <button
            className="popup-menu__item"
            onClick={() => {
              setAuthModalOpen(true);
              onClose();
            }}>
            {t('common.login')} / {t('common.register')}
          </button>
        )}

        <Link className="popup-menu__item" to={links.buy}>
          {t('header.buy')}
        </Link>
        <Link className="popup-menu__item" to={links.sell}>
          {t('header.sell')}
        </Link>
        <Link className="popup-menu__item" to={links.aboutUs}>
          {t('header.about_us')}
        </Link>
        {info.showOurTeamMenu && (
          <Link className="popup-menu__item" to={links.ourTeam}>
            Our team
          </Link>
        )}

        <Link
          className="popup-menu__item"
          to={links.savedListings}
          onClick={e => {
            if (!authorized) {
              e.preventDefault();
              setAuthModalOpen(true);
            }
          }}>
          {t('header.saved_listings')}
        </Link>
        <Link
          className="popup-menu__item"
          to={links.savedSearches}
          onClick={e => {
            if (!authorized) {
              e.preventDefault();
              setAuthModalOpen(true);
            }
          }}>
          {t('header.saved_searches')}
        </Link>
      </StyledAsideMenu>
    </StyledModal>
  );
};

export default AsideMenu;

const StyledAsideMenu = styled.div`
  padding: 16px 0 0;
  display: flex;
  flex-direction: column;
  .popup-menu {
    &__item {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: ${props => props.theme.colors.black};
      text-decoration: none;
      margin: 0 0 23px;
      text-align: unset;
      border: none;
      background: none;
      padding: 0;
    }
  }

  .auth.dropdown {
    margin-bottom: 20px;

    .dropdown__menu {
      position: relative !important;
      transform: none !important;
      width: 100%;
      background: none;
      box-shadow: none;
      transform: none;
      margin-top: 20px;
      padding: 0;

      .dropdown__option {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
      }
    }
    .dropdown__text {
      width: 100%;
      text-align: left;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
    }
  }
`;

const StyledModal = styled(Modal)`
  box-sizing: border-box;
  width: 310px;
  right: 0;
  left: auto;
  height: 100vh;
  background: #f8f8f8;
  .modal-bg {
    padding: 0;
  }
  .modal-content {
    min-width: 100%;
    height: 100%;
    border-radius: 0;
    background: #f8f8f8;
  }
`;
