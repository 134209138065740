import styled from '@emotion/styled';
import React from 'react';

const OrderedList: React.FC<React.HTMLAttributes<HTMLOListElement>> = ({ children, ...props }) => {
  return <StyledOrderedList {...props}>{children}</StyledOrderedList>;
};

export default React.memo(OrderedList);

const StyledOrderedList = styled.ol`
  margin: 40px 0 0;
  counter-reset: my-awesome-counter;
  list-style: none;
  padding-left: 60px;

  li {
    margin: 0 0 32px;
    counter-increment: my-awesome-counter;
    position: relative;
    text-align: left;

    &::before {
      content: counter(my-awesome-counter);
      position: absolute;
      width: 25px;
      height: 25px;
      background: ${props => props.theme.colors.primary};
      left: -50px;
      border-radius: 50%;
      text-align: center;
      font-size: 18px;
      line-height: 25px;
      color: #ffffff;
      top: 0;
    }
  }
`;
