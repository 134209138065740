const path = 'src/sites/homestox/views';

module.exports = [
  {
    path: 'company',
    component: `${path}/CompanyHomePage/page.tsx`
  },
  {
    path: 'company/about',
    component: `${path}/CompanyAboutPage/page.tsx`
  },
  {
    path: 'company/services',
    component: `${path}/CompanyServicesPage/page.tsx`
  },
  {
    path: 'company/plans',
    component: `${path}/CompanyCommissionPlansPage/page.tsx`
  }
];
