import loadable from '@loadable/component';

export const SellPage = loadable(() => import('../../src/views/SellPage'));
export const Header = loadable(() => import('../../src/components/Header'));
export const Logo = loadable(() => import('../../src/components/Logo'));
export const Footer = loadable(() => import('../../src/components/Footer'));
export const PrivacyContent = loadable(() => import('../../src/views/PrivacyPage/PrivacyContent'));
export const TermsContent = loadable(() => import('../../src/views/PrivacyPage/TermsContent'));
export const AboutUsPage = loadable(() => import('../../src/views/AboutUsPage'));
export const IndexPage = loadable(() => import('../../src/views/HomePage/IndexPage'));
