import React from 'react';
import { Global, css } from '@emotion/react';
import { theme } from 'sitedata';

const globalStyles = (
  <Global
    styles={css`
      body {
        font-family: 'Lato', sans-serif;
        font-size: 18px;
        line-height: 26px;
        color: #434343;
      }

      h1 {
        font-weight: 600;
        font-size: 36px;
        line-height: 44px;
        margin: 24px 0;
      }

      h2 {
        font-weight: 600;
        font-size: 28px;
        line-height: 42px;
        margin: 16px 0;
      }

      .button.primary {
        box-shadow: 0px 4px 10px rgba(255, 76, 0, 0.5) !important;
        font-size: 16px !important;
        line-height: 24px;
        height: 44px;
        padding: 10px 3rem;
      }

      @media (max-width: ${theme.breakpoints.sm}) {
        body {
          font-size: 16px;
          line-height: 26px;
        }

        h1 {
          font-size: 24px;
          line-height: 44px;
          margin: 20px 0;
        }

        h2 {
          font-size: 18px;
          line-height: 26px;
        }
      }
    `}
  />
);

export default globalStyles;
