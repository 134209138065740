const isDevEnv = process.env.NODE_ENV === 'development'
const siteName = process.env.GATSBY_SITE_DATA || 'common';

let fonts = require('./common/fonts.json');
try {
    fonts = require(`./${siteName}/fonts.json`);
} catch (e) {
  if (isDevEnv)
    console.info(`Sitedata | fonts : defaults are used`);
}

let _pages = require( `./common/pages` );
try {
  _pages = require( `./${siteName}/pages` );
} catch (e) {
  if (isDevEnv)
    console.info(`Sitedata | pages : defaults are used`);
}

const defaultInfo = require('./common/info.json');
const _info = require( `./${siteName}/info.json` );

module.exports.info = { ...defaultInfo, ..._info };
module.exports.siteName = siteName;
module.exports.pages = _pages;
module.exports.fonts = fonts;
module.exports.isDevEnv = isDevEnv;
