import React from 'react';
import clsx from 'clsx';

import styled from '@emotion/styled';
import { Icon } from 'components';

type Tab = { key: number | string; value?: string; text?: React.ReactNode | string; };
export interface TabsProps extends React.HTMLAttributes<HTMLDivElement> {
  centered?: boolean;
  items: Tab[];
  activeTab?: number | string;
  useValueAsKey?: boolean;
  mobile?: boolean;
  getTabProps?: (tab: Tab) => React.ButtonHTMLAttributes<HTMLButtonElement>;
  getTabStatus?: (tab: Tab) => { [key: string]: boolean; };
  onSelect?: (event: any, value?: any) => void;
}

const Tabs: React.FC<TabsProps> = ({
  className,
  centered,
  items,
  activeTab,
  useValueAsKey = false,
  onSelect,
  mobile,
  getTabProps,
  getTabStatus,
  ...rest
}) => {
  if (!Array.isArray(items)) return null;
  return (
    <TabsContainer className={clsx('tabs', { centered, mobile }, className)} {...rest}>
      {items.map((item, idx) => {
        const key = useValueAsKey ? item.value : item.key || idx;
        const active = activeTab === key;
        const tabStatus = getTabStatus?.(item);

        return (
          <TabItem
            data-cy={`button_tab_${key}`}
            key={key}
            className={clsx('tab-item', { active })}
            onClick={e => onSelect && onSelect(e, item)}
            {...getTabProps?.(item)}>
            {tabStatus?.completed && <Icon name="check" />}
            {item.text}
          </TabItem>
        );
      })}
    </TabsContainer>
  );
};

export default Tabs;

const TabsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  &.centered {
    justify-content: center;

    .tab-item {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

const TabItem = styled.button<any>`
  /** define a proper type for theme object here */
  display: flex;
  align-items: center;
  background: transparent;
  border: 0;
  outline: none;
  padding: 0;
  margin-right: 20px;
  font-family: Inter, sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.colors.dustyGray};
  transition: all 0.3s;
  cursor: pointer;
  border-bottom: 2px solid transparent;

  &:disabled {
    pointer-events: none;
  }

  &:last-of-type {
    margin: 0;
  }

  .icon {
    margin-right: 6px;
    fill: ${props => props.theme.colors.dustyGray};
    vertical-align: text-bottom;
  }

  &.active {
    border-bottom: 2px solid ${props => props.theme.colors.black};
    color: ${props => props.theme.colors.black};

    .icon {
      fill: ${props => props.theme.colors.black};
    }
    .icon-check {
      fill: ${props => props.theme.colors.white};
    }
  }
  &:last-child {
    margin-right: 0px;
  }
`;
