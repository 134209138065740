/* eslint-disable no-param-reassign */
import React, { useState } from 'react';
import noImage from 'assets/images/placeholder_no_image.jpg';
// import ReactImageAppear from 'react-image-appear';
// import { useIsMounted } from 'hooks';
import { info } from 'sitedata';

const cludfrontApi = info.CLOUDFRONT_API;

export interface ImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  error?: JSX.Element;
  resize?: string;
  srcList?: string | string[];
  onError?: () => void;
  cloudFront?: {
    key: string;
    size: string;
  };
}

const defaultError = (
  <img
    src={noImage}
    alt="image load error"
    style={{ objectFit: 'contain' }}
    className="img-error"
  />
);

const Image: React.FC<ImageProps> = ({
  src,
  cloudFront,
  error = defaultError,
  onError,
  alt = '',
  ...props
}) => {
  // const isMounted = useIsMounted();
  const [isError, setIsError] = useState(false);
  if (cloudFront?.key.includes('http')) src = cloudFront?.key;
  else src = cloudFront ? `${cludfrontApi}/fit-in/${cloudFront.size}/${cloudFront.key}` : src;
  if (!src) throw Error('Invalid src');

  // if (isLoading) return <>{loader}</>;
  if (isError) return error;

  return (
    <img
      src={src}
      alt={alt}
      onError={() => {
        onError?.();
        setIsError(true);
      }}
      {...props}
    />
  );
};

export default Image;
